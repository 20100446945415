@tailwind base;
@tailwind components;
@tailwind utilities;

.container{
    max-width: 1264px;
    margin: auto;
}

@media screen and (max-width:1290px) {
    .container{
        padding: 0 20px;
    }
}

body{
    font-family: "Montserrat", sans-serif;
    background-color: #f1f2f5;
}

/* .gradient {
    background: linear-gradient(to top right, #E8E2F6 ,#FEF6E9 );
  
    width: 100%; 
    height: 320px; 

    position: relative; 
}

.animation img {
    position: absolute;
    animation: moveAnimation 2s infinite alternate;
}

@keyframes moveAnimation {
    from {
        left: 0;
    }
    to {
        left: 50%;
    }
} */


.gradient {
    background: linear-gradient(to top right, #E8E2F6 ,#FEF6E9 );
    width: 100%;
    height: 320px;
    position: relative;
}

.animation {
    position: absolute;
    width: 100%; /* .gradient'in tam boyutunda olması için */
    height: 100%; /* .gradient'in tam boyutunda olması için */
    display: flex;
    justify-content: center;
    align-items: center;
}

.animation img {
    animation: moveAnimation 2.7s infinite alternate;
}

@keyframes moveAnimation {
    from {
        transform: translateX(-40%);
    }
    to {
        transform: translateX(40%);
    }
}

@media screen and (max-width:550px) {
    @keyframes moveAnimation {
        from {
            transform: translateX(-30%);
        }
        to {
            transform: translateX(30%);
        }
    }
}

@media screen and (max-width:490px) {
    @keyframes moveAnimation {
        from {
            transform: translateX(-25%);
        }
        to {
            transform: translateX(25%);
        }
    }
}
@media screen and (max-width:450px) {
    @keyframes moveAnimation {
        from {
            transform: translateX(-20%);
        }
        to {
            transform: translateX(20%);
        }
    }
}

@media screen and (max-width:380px) {
    @keyframes moveAnimation {
        from {
            transform: translateX(0%);
        }
        to {
            transform: translateX(0%);
        }
    }
}

/* responsive nav */
#onclick{
    display: none;
}
  
.lbl-resp{
    position: relative;
    float: right;
    cursor: pointer;
    display: none;
}

@media screen and (max-width: 950px) { 
    .lbl-resp{
      display: block;
    }
  
    .rsp-header{
      margin-top: 100px;
    }
   
    .menu{
      margin: 0px;
      padding: 0px;
      width: 100%;
    }
  
    .menu label{
      right: 30px;
    }
    .menu ul{
      position: absolute;
      z-index: 99;
      transition: 1s;
      left: -225px;
      margin-top: 60px ;
      margin-left: 0px;
      width: 225px;
      flex-direction: column;
      background-color: #fff;
      height: 100vh;
  
    }
  
    .menu ul li{
      display: block;
      width: 100%;
      padding: 20px;  
      
    }
  
    .menu ul li a{
      width: 100%;
    }
  
    #onclick:checked~ul{
      left: 0px;
    }
}